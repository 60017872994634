import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 商品激活
 */
export const activateGoods = (type, params) => post('/tradeapp/trace/active/' + type, params)

/**
 * 获取激活记录列表
 */
export const getActivateList = (type, params) => get('/tradeapp/trace/active/ent/list/' + type, params)

/**
 * 获取激活记录详情
 */
export const getActivateDetail = (type, params) => get('/tradeapp/trace/active/ent/' + type, params)

/**
 * 删除激活记录
 */
export const deleteActivate = (type, params) => del('/tradeapp/trace/active/' + type, params)

/**
 * 通过激活ID查询追溯码列表
 */
export const getActivateYardsList = (type, params) => get(`/tradeapp/trace/active/ent/${type}/code/list`, params)

/**
 * 获取生产任务信息
 */
export const getProductTaskInfo = (id) => get('/tradeapp/trace/task/ent/' + id)